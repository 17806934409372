import React from "react";
import Main from "../layouts/Main";

export default () => {
  return (
    <Main>
      <h1>Not found</h1>
    </Main>
  );
};
